<template>
  <section class="scrolling-part">
    <div class="has-px is-flex is-justify-content-space-between">
      <div class="total-investments metric-block has-background-white is-flex is-flex-direction-column is-justify-content-center has-text-centered">
        <div class="metric-title has-text-weight-semibold">Total Investments</div>
        <div class="metric-value is-relative">
          <span class="currency has-text-blue has-text-weight-bold">{{ country }}D</span>
          <span class="symbol has-text-blue has-text-weight-semibold">$</span>
          <span class="value has-text-blue">{{totalInvestments | formatNumber}}</span>
        </div>
      </div>
      <div class="total-distributions metric-block has-background-white is-flex is-flex-direction-column is-justify-content-center has-text-centered">
        <div class="metric-title has-text-weight-semibold">Total Returns</div>
        <div class="metric-value is-relative">
          <span class="currency has-text-blue has-text-weight-bold">{{ country }}D</span>
          <span class="symbol has-text-blue has-text-weight-semibold">$</span>
          <span class="value has-text-blue">{{totalDistributions | truncateBalance | formatNumber}}</span>
        </div>
      </div>
    </div>
    <ProgressBar
      v-if="twelveMonthsInvestmentRemainder !== null"
      :progressBarRate="twelveMonthsInvestmentRate"
      :legendText="progressBarLegendText"
      @toolTipAction="showInvestmentRestrictionModal = true"
    >
    </ProgressBar>
    <slot name="metaverse"></slot>
    <div class="portfolio-list">
      <div class="section-title has-text-weight-semibold">Your portfolio</div>
      <template v-if="propertyList.length > 0">
        <div
          class="property-invested has-background-white is-clickable"
          v-for="(property, index) in propertyList"
          :key="index"
          @click="toInvestmentDetailsPage(property.nameSpaceUrl, property.propertyId, property.corporationId)"
        >
          <div class="property-preview is-relative" :style="`background: url(${property.primaryImage}) center/cover no-repeat;`">
            <div class="type-of-investment is-flex is-align-items-center">
              <img :src="property.typeIconUrl" alt="type of investment">
              <span class="has-text-blue type">{{property.typeOfInvestment}}</span>
            </div>
          </div>
          <div class="property-nickname is-uppercase has-text-weight-semibold">{{property.nickname}}</div>
          <div class="property-name">{{property.name}} {{flag}}</div>
          <div class="metrics is-flex is-justify-content-space-between">
            <div class="net-investment">
              <div class="name">Net Investment</div>
              <div class="value has-text-weight-semibold">${{property.totalInvestment | formatNumber}} {{country}}D</div>
            </div>
            <div class="distributions" v-if="property.distribution">
              <div class="name">Distributions</div>
              <div class="value has-text-weight-semibold">${{property.distribution | truncateBalance | formatNumber}} {{country}}D</div>
            </div>
            <div class="irr">
              <div class="name">Estimated IRR</div>
              <div class="value has-text-weight-semibold">{{property.irr ? `${property.irr}%` : '—'}}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="portfolio-empty-state">
          <div class="placeholder-text has-text-weight-semibold has-text-centered">Nothing here yet</div>
          <div
            class="invest-button has-text-weight-bold has-text-centered"
            @click="toPropertyForSalePage">
            <span class="is-clickable is-inline-flex is-align-items-center">
              <span class="has-text-primary">INVEST</span>
              <img src="@assets/icons/plus-primary.svg" alt="plus-icon">
            </span>
          </div>
        </div>
      </template>
      <StandardModal
        :showModal="showInvestmentRestrictionModal"
        :closeModal="closeInvestmentRestrictionModal"
        :buttonTappedAction="closeInvestmentRestrictionModal"
        title="Remaining Investment Amount"
        buttonTitle="Close"
        >
        <div class="modal-text">
          <div class="mb-4">
            Investors who are not Eligible Investors and resident in Alberta, New Brunswick, Nova Scotia, Ontario,
            Québec and Saskatchewan who are purchasing under the Offering Memorandum Prospectus Exemption (“OM Exemption”)
            are subject to a ${{ twelveMonthsInvestmentLimitText }} limit on securities purchased under the OM Exemption in the preceding 12-month period.
          </div>
        </div>
      </StandardModal>
    </div>
  </section>
</template>
<script>
import StandardModal from '@components/modal/standard-modal.vue'
import ProgressBar from '@components/progress-bar/animated-progress-bar.vue'
import { formatter, truncate } from '@utils/common-methods/numberFormatter'
import axios from '@lib/axios/middleware'
import { getInvestmentSummary, getInvestmentList } from '@api/portfolio'
import { investorInAvailableRegion } from '@utils/common-methods/common'

export default {
  components: {
    StandardModal,
    ProgressBar,
  },
  data() {
    return {
      totalInvestments: 0,
      totalDistributions: 0,
      totalRoi: 0,
      propertyList: [],
      inAvailableRegion: true,
      twelveMonthsInvestmentRemainder: null,
      showInvestmentRestrictionModal: false,
    }
  },
  props: {
    country: {
      type: String,
      default: 'CA',
      validator(value) {
        return ['CA', 'US'].includes(value)
      },
    },
  },
  computed: {
    flag() {
      return this.country === 'CA' ? '🇨🇦' : '🇺🇸'
    },
    countryPayload() {
      return this.country === 'CA' ? 'Canada' : 'United_States'
    },
    twelveMonthsInvestmentLimit() {
      return Number(this.totalInvestments) + Number(this.twelveMonthsInvestmentRemainder)
    },
    twelveMonthsInvestmentRate() {
      const rate = this.totalInvestments / this.twelveMonthsInvestmentLimit * 100
      return rate < 1 && rate > 0 ? 1 : Number(truncate(rate, 0))
    },
    twelveMonthsInvestmentLimitText() {
      return formatter(this.twelveMonthsInvestmentLimit, 0)
    },
    progressBarLegendText() {
      return `$${formatter(truncate(this.twelveMonthsInvestmentRemainder, 2))} Remaining Investment Amount`
    },
  },
  filters: {
    formatNumber(value) {
      return formatter(value)
    },
    truncateBalance(value) {
      return truncate(Number(value), 2)
    },
  },
  created() {
    this.getPortfolioData()
  },
  methods: {
    getPortfolioData() {
      axios.all([
        getInvestmentSummary(this.countryPayload),
        getInvestmentList(this.countryPayload),
      ]).then((data) => {
        if (data[0].success) {
          const { totalInvestments, totalDistributions, totalRoi, twelveMonthsInvestmentRemainder } = data[0].data.summary
          this.totalInvestments = totalInvestments
          this.totalDistributions = totalDistributions
          this.totalRoi = totalRoi
          this.twelveMonthsInvestmentRemainder = twelveMonthsInvestmentRemainder
        }
        if (data[1].success) {
          const list = JSON.parse(JSON.stringify(data[1].data.investments))
          list.map((property) => {
            property.typeIconUrl = this.getTypeIconUrl(property.typeOfInvestment)
          })
          this.propertyList = list
        }
      })
      const investor = JSON.parse(localStorage.getItem('investor'))
      this.inAvailableRegion = investorInAvailableRegion(investor.country, investor.administrativeAreaLevel1)
    },
    getTypeIconUrl(type) {
      switch (type) {
      case 'Value-Add':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-value-add.svg'
      case 'Opportunistic':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-opportunistic.svg'
      case 'Core':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-core.svg'
      case 'Core Plus':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-core-plus.svg'
      }
    },
    toPropertyForSalePage() {
      if (!this.inAvailableRegion) {
        return this.$router.push({
          name: 'regionNotAvailable',
          query: { title: 'Invest in a property' }
        })
      }
      this.$router.push('/allProperties')
    },
    toInvestmentDetailsPage(nameSpaceUrl, propertyId, corporationId) {
      this.$router.push(`/portfolio/${nameSpaceUrl}/property/${propertyId}?corporationId=${corporationId}`)
    },
    closeInvestmentRestrictionModal() {
      this.showInvestmentRestrictionModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.scrolling-part {
  padding: 20px 0 80px;
  .has-px {
    padding: 0 25px;
  }
  .metric-block {
    width: calc(50% - 6px);
    height: 97px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.11);
    border-radius: 12px;
    .metric-title {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 8px;
    }
    .metric-value {
      .currency {
        position: absolute;
        right: 4px;
        top: -2px;
        font-size: 12px;
      }
      .value {
        font-size: 32px;
        line-height: 38px;
      }
    }
  }
  .total-roi {
    width: 157px;
    height: 97px;
  }
  .total-distributions .symbol,
  .total-investments .symbol {
    font-size: 20px;
    margin-right: 4px;
    vertical-align: top;
    line-height: 30px;
  }
  .total-roi .symbol {
    margin-left: 4px;
    font-size: 20px;
  }
  .portfolio-list {
    padding: 0 25px;
    margin-top: 35px;
    .section-title {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 16px;
    }
    .property-invested {
      box-shadow: 0px 0px 10px rgba(45, 41, 38, 0.05);
      border-radius: 12px;
      margin-bottom: 16px;
      padding: 12px;
      border-radius: 12px;
      .property-preview {
        height: 87px;
        border-radius: 8px;
        overflow: hidden;
        .type-of-investment {
          height: 27px;
          position: absolute;
          right: 0;
          bottom: 0;
          border-top-left-radius: 12px;
          background: #F0EEF8;
          padding: 0 10px 0 7px;
          .type {
            font-size: 14px;
            margin-left: 4px;
          }
        }
      }
      .property-nickname {
        font-size: 16px;
        line-height: 19px;
        padding: 10px 0 4px;
      }
      .property-name {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .metrics {
        padding-bottom: 10px;
        .irr {
          padding-right: 6px;
        }
        .name {
          color: #9185AE;
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 2px;
        }
        .value {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
    .portfolio-empty-state {
      height: 241px;
      background: url('../../../assets/images/portfolio/portfolio-empty-state.svg') center/contain no-repeat;
      border: 1px solid #F0EEF8;
      border-radius: 12px;
      .placeholder-text {
        font-size: 16px;
        color: #9185AE;
        margin-top: 102px;
      }
      .invest-button {
        font-size: 12px;
        margin-top: 78px;
        img {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="progress-bar-container has-px is-flex is-flex-direction-column is-justify-content-center has-text-centered">
    <progress class="progress is-medium is-blue" :value="displayValue" max="100"></progress>
    <div class="legend is-flex is-flex-direction-row is-justify-content-center is-align-items-center has-text-centered has-text-weight-semibold" v-if="legendText">
      {{legendText}}
      <div class="tooltip is-inline-flex is-align-items-center is-clickable ml-2" @click="toolTipAction">
        <img src="@assets/icons/info.svg" alt="tooltip">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      displayValue: 0,
    }
  },
  props: {
    progressBarRate: {
      type: Number,
      required: true,
    },
    legendText: {
      type: String,
      default: '',
    }
  },
  mounted() {
    this.loadProgressBar()
  },
  methods: {
    loadProgressBar() {
      setTimeout(() => {
        this.displayValue = this.progressBarRate
      }, 2000)
    },
    toolTipAction() {
      this.$emit('toolTipAction')
    },
  },
}
</script>

<style lang="scss" scoped>
.progress-bar-container {
  padding-top: 20px !important;
  .progress {
    margin-bottom: 0;
  }
  .progress::-webkit-progress-value {
    transition: width 0.5s ease;
  }
  .legend {
    font-size: 14px;
    padding-top: 5px;
  }
}
</style>

<template>
  <div class="portfolio-landing-page">
    <WelcomeMessage/>
    <div class="sticky-header has-background-white">
      <MenuAndLogo :showReferral="true"></MenuAndLogo>
    </div>
    <b-tabs size="is-medium" class="mt-5" v-model="activeCountry">
      <b-tab-item
        v-for="country in countries"
        :key="country.value"
        :label="country.label"
        :value="country.value"
      >
        <Overview :country="country.value">
          <template v-if="showMetaverse" v-slot:metaverse>
            <Metaverse :country="country.value"></Metaverse>
          </template>
        </Overview>
      </b-tab-item>
    </b-tabs>
  </div>
</template>
<script>
import WelcomeMessage from '@components/desktop/header-welcome-message.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import Overview from './Overview.vue'
import Metaverse from './Metaverse.vue'
import nps from '@utils/mixins/nps.js'
import { getFeatureToggle } from '@api/common'
const COUNTRIES = [
  {
    label: '🇨🇦 Canada',
    value: 'CA',
  },
  {
    label: '🇺🇸 United States',
    value: 'US',
  },
]

export default {
  mixins: [nps],
  components: {
    WelcomeMessage,
    MenuAndLogo,
    Overview,
    Metaverse,
  },
  data() {
    return {
      showMetaverse: false,
      countries: Object.freeze(COUNTRIES),
      activeCountry: 'CA',
    }
  },
  created() {
    this.fetchMetaverseToggle()
  },
  methods: {
    fetchMetaverseToggle() {
      getFeatureToggle('metaverse').then((res) => {
        if (res.success) {
          this.showMetaverse = res.data.enabled
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.portfolio-landing-page {
  background-color: #fafafa;
  padding-top: 68px;
  overflow: auto;
  .sticky-header {
    width: 100%;
    height: 68px;
    box-shadow: 0px 0px 12px rgba(45, 41, 38, 0.05);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
  }
  .b-tabs {
    ::v-deep .tabs {
      padding: 0 25px;
      span {
        font-weight: 600;
      }
      .is-active span {
        color: var(--addy-blue);
      }
    }
    ::v-deep .tab-content {
      padding: 0;
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .portfolio-landing-page {
    min-height: $min-height-mobile-container;
    padding-top: 0;
    background-color: transparent;
  }
}
</style>

<template>
  <div class="metaverse is-relative">
    <div class="top-sections">
      <div class="section-title is-flex is-justify-content-space-between is-align-items-center">
        <template v-if="isEditingTitle">
          <div class="input-field is-flex is-justify-content-space-between is-align-items-center">
            <input v-model.trim="newCityName" maxlength="20" :placeholder="cityName">
            <div class="pt-2 pb-1 pl-2 pr-1 is-clickable" @click="saveCityName">
              <img src="@assets/icons/check-lavender.svg" alt="okay">
            </div>
          </div>
          <div class="pl-2 close-icon is-clickable" @click="isEditingTitle = false">
            <img src="@assets/icons/close-lavender.svg" alt="close">
          </div>
        </template>
        <template v-else>
          <span class="has-text-weight-semibold default-title">{{cityName}}</span>
          <img src="@assets/icons/edit.svg" alt="edit" @click="enableCityNameEdit" class="is-clickable"  v-if="membershipPaid">
        </template>
      </div>
      <div class="tooltip is-inline-flex is-align-items-center is-clickable" @click="showMetaverseModal = true">
        <img src="@assets/icons/info.svg" alt="tooltip">
        <span class="tooltip-label">About your city</span>
      </div>
    </div>
    <div class="total-investments-banner has-text-centered" v-show="activeBlock === undefined && totalProperties">
      <div>Investments</div>
      <div class="has-text-blue has-text-weight-semibold total-properties">{{totalProperties}}</div>
    </div>
    <div class="metrics-wrapper">
      <div class="metrics-inner-wrapper is-relative">
        <div
          :class="`metrics has-background-white slot${idx + 1} ${activeProperty === idx ? 'active-metric' : ''}`"
          v-for="(metric, idx) in metaverseImages[activeBlock]"
          :key="idx">
          <div class="nickname has-text-centered has-text-blue has-text-weight-semibold">{{metric.nickname}}</div>
          <template v-if="metric.saleStatus === 'settled'">
            <div class="titles is-flex is-justify-content-space-between">
              <span>My Investments</span>
              <span>Ownership</span>
            </div>
            <div class="values is-flex is-justify-content-space-between is-align-items-center">
              <div>
                <span class="sign has-text-weight-semibold">$ </span>
                <span class="num has-text-weight-semibold">{{metric.totalInvestment | formatNumberNoDecimal}} {{country2currency(metric.country)}}</span>
              </div>
              <div>
                <span class="num has-text-weight-semibold">{{metric.ownershipPercentage}}</span>
                <span class="has-text-weight-semibold"> %</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="titles has-text-centered">
              <span>My Investments</span>
            </div>
            <div class="values has-text-centered">
              <span class="sign has-text-weight-semibold">$ </span>
              <span class="num has-text-weight-semibold">{{metric.totalInvestment | formatNumberNoDecimal}} {{country2currency(metric.country)}}</span>
            </div>
            <div></div>
          </template>
        </div>
      </div>
    </div>
    <div class="metaverse-block">
      <div :class="`swiper-container metaverse-swiper metaverse-swiper-${country}`" v-if="metaverseImages.length > 0">
        <div class="swiper-wrapper">
          <div
            v-for="(block, index) in metaverseImages"
            :key="index"
            :class="`swiper-slide ${(!$isMobile && metaverseImages.length > 1) ? 'pb-4' : ''}`"
            @click.self="resetPropertyPosition">
              <div class="is-relative">
                <div class="is-relative land-wrapper">
                  <img
                    src="@assets/images/portfolio/metaverse-land.svg"
                    alt="land"
                    :class="`base-land ${activeProperty === undefined ? '' : 'sank'}`"
                    @click="resetPropertyPosition">
                </div>
                <div
                  v-for="(property, i) in block"
                  :key="i"
                  :class="`property-slot slot${i + 1} ${activeBlock === index && activeProperty === i ? 'active-property' : (activeProperty === undefined ? '' : 'faded-property')}`">
                  <img :src="property.miniPropUrl" alt="property image" class="property-image is-clickable" @click.stop="highlightProperty(index, i)">
                </div>
              </div>
            </div>
        </div>
        <div class="metaverse-swiper-navigation is-align-items-center" v-if="metaverseImages.length > 1">
          <div :class="`swiper-button-prev ${navigationPrevDisabled}`" @click="slidePrev" v-if="!$isMobile"></div>
          <div class="swiper-pagination"></div>
          <div :class="`swiper-button-next ${navigationNextDisabled}`" @click="slideNext" v-if="!$isMobile"></div>
        </div>
      </div>
      <div class="empty-state has-text-centered is-relative" v-else>
        <img src="@assets/images/portfolio/metaverse-land.svg" alt="empty state">
        <div class="text">
          <div class="text1 has-text-blue has-text-weight-semibold">Your city is in its</div>
          <div class="text2 has-text-blue has-text-weight-semibold">pre-construction phase 🌱</div>
          <div class="text3">
            <template v-if="membershipPaid">
              <span class="has-text-primary is-clickable" @click="toPropertyForSale">Invest</span>
              in a property to start building it!
            </template>
            <template v-else>
              <span class="has-text-primary is-clickable" @click="toMembershipPage">Become a paid member</span>
              to start building it!
            </template>
          </div>
        </div>
      </div>
    </div>
    <StandardModal
      :showModal="showMetaverseModal"
      :closeModal="closeMetaverseModalAction"
      :buttonTappedAction="closeMetaverseModalAction"
      title="About your city"
      buttonTitle="Close"
      >
      <div class="modal-text">
        <div class="mb-4">
          Your mini-city of mini-props is a unique collection of all your addys in one place. Watch your mini-city grow 🌱as your portfolio grows! 🏘
        </div>
        <div class="mb-1">
          *We cannot predict the real estate market, so the actual value of your investment may differ.
          You are not liable beyond your investment amount.
        </div>
        <div class="has-text-centered mt-3">
          <a class="has-text-weight-semibold has-text-blue read-more" @click="readMore()">Read more</a>
        </div>
      </div>
    </StandardModal>
  </div>
</template>
<script>
import StandardModal from '@components/modal/standard-modal.vue'
import { mapState } from 'vuex'
import SwiperCore, { Pagination } from 'swiper/core'
import 'swiper/swiper-bundle.css'
import { formatter } from '@utils/common-methods/numberFormatter'
import { getMetaverseData, updateCityName } from '@api/portfolio'
import { investorInAvailableRegion, embeddedInIos } from '@utils/common-methods/common'

export default {
  data() {
    return {
      isEditingTitle: false,
      cityName: 'Your city',
      newCityName: undefined,
      showMetaverseModal: false,
      metaverseImages: [],
      totalProperties: 0,
      membershipPaid: false,
      activeBlock: undefined,
      activeProperty: undefined,
      investor: {},
      metaverseSwiper: undefined,
    }
  },
  computed: {
    ...mapState(['propertyBaseUrlOnMenu']),
    navigationPrevDisabled() {
      if (!this.metaverseSwiper) return
      return this.metaverseSwiper.activeIndex === 0 ? ' disabled' : ''
    },
    navigationNextDisabled() {
      if (!this.metaverseSwiper) return
      return this.metaverseSwiper.activeIndex === this.metaverseImages.length - 1 ? ' disabled' : ''
    },
  },
  props: {
    country: {
      type: String,
      default: 'CA',
    },
  },
  filters: {
    formatNumber(value) {
      return formatter(value)
    },
    formatNumberNoDecimal(value) {
      return formatter(value, 0)
    },
  },
  components: {
    StandardModal,
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      getMetaverseData().then((result) => {
        if (!result.success) return
        const { name, boughtProperties, membershipPaid } = result.data
        if (name) this.cityName = name
        this.totalProperties = boughtProperties.length
        this.membershipPaid = membershipPaid
        this.metaverseImages = this.parseMetaverseImages(boughtProperties)
        this.$nextTick(() => {
          this.metaverseImages.length && this.initializeCarousel()
        })
      })
      this.investor = JSON.parse(localStorage.getItem('investor'))
    },
    parseMetaverseImages(metaverseImages) {
      let temp = []
      const newArr = []
      metaverseImages.map((image, index) => {
        if (temp.length < 4) {
          temp = [...temp, image]
          if (index === metaverseImages.length - 1) newArr.unshift(temp)
        } else {
          newArr.unshift(temp)
          temp = [image]
          if (index === metaverseImages.length - 1) newArr.unshift(temp)
        }
      })
      return newArr
    },
    initializeCarousel() {
      SwiperCore.use([Pagination])
      const config = this.metaverseImages.length > 1 ? {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      } : {}
      /* eslint-disable-next-line */
      this.metaverseSwiper = new SwiperCore(`.metaverse-swiper-${this.country}`, config)
      this.metaverseSwiper.on('sliderFirstMove', () => {
        this.resetPropertyPosition()
      })
    },
    enableCityNameEdit() {
      this.newCityName = undefined
      this.isEditingTitle = true
    },
    saveCityName() {
      if (!this.newCityName) return
      updateCityName({
        city_name: this.newCityName,
      }).then((result) => {
        if (!result.success) return
        this.cityName = this.newCityName
        this.newCityName = undefined
        this.isEditingTitle = false
      })
    },
    closeMetaverseModalAction() {
      this.showMetaverseModal = false
    },
    toPropertyForSale() {
      this.$router.push('/allProperties')
    },
    toMembershipPage() {
      const inAvailableRegion = investorInAvailableRegion(this.investor.country, this.investor.administrativeAreaLevel1)
      if (!inAvailableRegion) {
        return this.$router.push({
          name: 'regionNotAvailable',
          query: { title: 'Become a member' }
        })
      }
      // returnUrl is used to differentiate the membership buy flow started from dashboard
      this.$router.push(`/membership/options?returnUrl=${encodeURIComponent('/portfolio')}`)
    },
    highlightProperty(activeBlock, activeProperty) {
      if (this.activeBlock === undefined && this.activeProperty === undefined) {
        this.activeBlock = activeBlock
        this.activeProperty = activeProperty
      } else {
        this.activeBlock = undefined
        this.activeProperty = undefined
      }
    },
    resetPropertyPosition() {
      this.activeBlock = undefined
      this.activeProperty = undefined
    },
    readMore() {
      const url = 'https://www.addyinvest.com/2022/01/16/the-addyverse-is-coming-soon/'
      if (embeddedInIos()) return window.webkit.messageHandlers.openURL.postMessage(url)
      window.open(url)
    },
    slidePrev() {
      this.metaverseSwiper.slidePrev()
    },
    slideNext() {
      this.metaverseSwiper.slideNext()
    },
    country2currency(country) {
      return country === 'United States' ? 'USD' : 'CAD'
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.metaverse {
  padding-top: 84px;
  .top-sections {
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 18px;
    left: 0;
    .section-title {
      height: 29px;
      padding: 0 25px;
      .default-title {
        font-size: 24px;
        line-height: 29px;
      }
      .input-field {
        width: 293px;
        border-bottom: 1.5px solid #9185AE;
        input {
          padding: 0;
          font-size: 20px;
          font-weight: 600;
          width: 260px;
          height: 24px;
          border: none;
          background-color: transparent;
        }
        input::placeholder {
          color: rgba(44, 41, 38, 0.25);
          font-size: 20px;
          font-weight: 600;
        }
      }
      .close-icon {
        margin-top: 2px;
      }
    }
    .tooltip {
      padding-left: 25px;
      margin-top: 19px;
      .tooltip-label {
        font-size: 16px;
        margin-left: 9px;
      }
    }
  }
  .total-investments-banner {
    position: absolute;
    z-index: 3;
    right: 25px;
    top: 60px;
    width: 83px;
    height: 62px;
    background-color: #F0EEF8;
    border-radius: 12px;
    padding-top: 13px;
    .total-properties {
      font-size: 16px;
      line-height: 22px;
      margin-top: 2px;
    }
  }
  .metrics-wrapper {
    width: 100vw;
    padding: 0 14px;
    position: absolute;
    left: 0;
    top: 14px;
    .metrics-inner-wrapper {
      width: 100%;
      height: 370px;
    }
    .metrics {
      width: 50%;
      padding: 0 12px;
      position: absolute;
      border-radius: 12px;
      filter: drop-shadow(0px 0px 8px rgba(45, 41, 38, 0.1));
      opacity: 0;
      transition: all 0.5s;
      &.slot1 {
        left: 25%;
        top: 0;
      }
      &.slot2 {
        left: 0;
        top: 51px;
      }
      &.slot3 {
        right: 0;
        top: 51px;
      }
      &.slot4 {
        left: 25%;
        top: 102px;
      }
      &.active-metric {
        opacity: 1;
        z-index: 10;
      }
      .nickname {
        font-size: 16px;
        line-height: 18px;
        padding: 8px 0;
        border-bottom: 1px solid rgba(39, 50, 81, 0.07);
      }
      .titles {
        padding: 8px 0 6px;
        span {
          color: #9185AE;
        }
      }
      .values {
        padding-bottom: 8px;
        .sign {
          vertical-align: top;
          line-height: 13px;
        }
        .num {
          font-size: 16px;
        }
        .placeholder {
          font-size: 13px;
        }
      }
    }
  }
  .metaverse-block {
    .metaverse-swiper {
      .swiper-slide {
        padding: 60px 14px 0;
        .land-wrapper {
          height: 250px;
          .base-land {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            transition: all 0.5s;
            &.sank {
              top: 10px;
              filter: blur(1.5px);
            }
          }
        }
        .property-slot {
          width: 50%;
          height: 160px;
          position: absolute;
          &.slot1 {
            left: 25%;
            bottom: 9.28rem;
          }
          &.slot2 {
            width: calc(50% + 0.062rem);
            left: -0.044rem;
            bottom: 6.17rem;
          }
          &.slot3 {
            right: 0.0625rem;
            bottom: 6.19rem;
          }
          &.slot4 {
            left: calc(25% - 0.04rem);
            bottom: 3.06rem;
          }
          &.active-property {
            .property-image {
              bottom: 10px;
              z-index: 2;
            }
          }
          &.faded-property {
            .property-image {
              bottom: -10px;
              filter: blur(1.5px);
            }
          }
          .property-image {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: all 0.5s;
          }
        }
      }
      .metaverse-swiper-navigation {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        z-index: 100;
        .swiper-pagination {
          margin: 0 40px;
          position: unset;
          display: flex;
        }
        .swiper-button-prev,
        .swiper-button-next {
          position: unset;
          opacity: 0.3;
          color: #4A26AA !important;
          &.disabled {
            opacity: 0.3 !important;
            cursor: unset;
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .empty-state {
      padding: 40px 14px 0;
      > img {
        width: 100%;
      }
      .text {
        position: absolute;
        left: 0;
        top: 55px;
        width: 100%;
        .text1,
        .text2 {
          font-size: 16px;
          line-height: 19px;
        }
        .text3 {
          font-size: 16px;
          line-height: 19px;
          color: #867aa5;
          margin-top: 28px;
        }
      }
    }
  }
  .modal {
    .read-more {
      font-size: 14px;
      line-height: 17px;
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .metaverse {
    .metrics-wrapper {
      width: 100%;
    }
    .metaverse-block {
      .metaverse-swiper {
        .swiper-slide {
          .land-wrapper {
            height: 300px;
          }
          .property-slot {
            &.slot1 {
              bottom: 10.4rem;
            }
            &.slot2 {
              bottom: 6.3rem;
            }
            &.slot3 {
              bottom: 6.3rem;
            }
            &.slot4 {
              bottom: 2.24rem;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.metaverse-swiper >>> .swiper-pagination-bullet {
  background: #9185AE;
  margin: 0 4px;
}
</style>
